import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout } from '@components';

const Gallery: React.FC<any> = ({ data, pageContext: { locale } }) => {
    const { allStrapiGallery } = data;
    return (
        <Layout lang={locale} path="/gallery">
            <h1 className="font-bold text-center mt-28 ">
                {locale == 'en' ? 'Gallery' : 'Galerie'}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {allStrapiGallery.nodes.map(({ slug, Cover, Title }) => {
                    return (
                        <Link key={slug} to={'/gallery/' + slug}>
                            <div className="m-5 text-center ">
                                <div>
                                    {Cover && (
                                        <GatsbyImage
                                            className="object-center w-40 h-auto max-h-auto md:w-60 "
                                            image={Cover.localFile.childImageSharp.gatsbyImageData}
                                            alt={Title}
                                            objectFit="contain"
                                            imgClassName="hover:scale-110 !transition-all scale-100 delay-75 "
                                            objectPosition={'center'}
                                        />
                                    )}
                                </div>
                                <h4 className="m-4 mt-2 font-normal text-center">{Title}</h4>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </Layout>
    );
};

export default Gallery;

export const query = graphql`
    query Gallery($locale: String!) {
        allStrapiGallery(sort: { fields: Date, order: DESC }, filter: { locale: { eq: $locale } }) {
            nodes {
                slug
                Title
                Cover {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;
